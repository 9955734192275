/* Variables */
:root {
    --primary: #bf1e2e !important;
    --primaryDark: #bf1e2ecc;
    --primaryLight: #da2335;
    --secondary: #a1a1a1 !important;
    --secondaryDark: #8a8a8a !important;
    --text: #212121;
    --textLight: #939393;
    --light: #ecf2fa !important;
    --grey: #d2d2d2;
    --transition: 0.35s ease;
    --error: #f44336;
}
.cursor-pointer{
    cursor: pointer !important;
}

.zi-1{
    z-index: 1 !important;
}
.bg--light{
    background-color: var(--light);
}

.bg--primary{
    background-color: var(--primary);
}

.text--primary{
    color: var(--primary) !important;
}

.text--primary-dark{
    color: var(--primaryDark) !important;
}

.font-weight-600{
    font-weight: 600 !important;
}

.mb-50{
    margin-bottom: 5rem !important;
}

.mt-50{
    margin-top: 5rem !important;
}
.pb-50{
    padding-bottom: 5rem !important;
}

.pt-50{
    padding-top: 5rem !important;
}
.border--light{
    border: 1px solid var(--grey) !important;
}
.border--error{
    border: 1px solid red !important;
}
.rounded-box{
    background-color: white;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
    border-radius: 1rem;
    transition: var(--transition);
}
.rounded-box:hover{
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
}

@media (min-width: 575px){
    html body .container-fluid {
        padding-left: 30px;
        padding-right: 30px;
    }
}

html {
    font-size: 62.5%;
}

/* Typography */

html body {
    font-size: 1.4rem;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: var(--text);
    background: var(--light);
    position: relative;
}
@media (min-width: 768px){
    html body {
        padding-bottom: 126px;
        min-height: 100vh;
    }
}

html body p{
    margin: 0;
}

html body a{
    color: var(--text);
    transition: var(--transition);
}

html body a:hover{
    color: var(--text);
    opacity: 0.5;
    text-decoration: none;
}

html body .content{
    margin: 3rem 0;
}

html body h1, html body .h1{
    font-size: 3rem;
    font-weight: 700;
}

html body h2, html body .h2{
    font-size: 2rem;
    font-weight: 600;
}

html body h3, html body .h3{
    font-size: 1.7rem;
    font-weight: 600;
}
html body h4, html body .h4{
    font-size: 1.4rem;
    font-weight: 600;
}

/* Buttons */

html body .btn{
    border-radius: 10rem;
    padding: 1rem 3rem;
    font-size: 1.5rem;
    font-weight: 600;
    min-width: 18.5rem;
    -webkit-box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.2);
}
html body .btn:hover{
    -webkit-box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.3);
}
html body .btn--primary{
    background-color: var(--primary);
    color: white;
}
html body .btn--primary:hover{
    background-color: var(--primaryDark);
    color: white;
}
html body .btn--secondary{
    background-color: var(--secondary);
    color: white;
}
html body .btn--secondary:hover{
    background-color: var(--secondaryDark);
    color: white;
}
html body .btn--datepicker{
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    background-color: var(--primary);
    border: 0;
    color: white;
    transition: var(--transition);
    border-radius: 50%;
}
html body .btn--datepicker:focus{
    outline: 0;
}

/* Dropdown */

.dropdown .dropdown-menu{
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
    font-size: 1.2rem;
    border: none;
}

/* Nav */
li {
    list-style-type: none;
}
html body .nav__dropdown{
    list-style-type: none;
    padding-left: 1rem;
}
html body .nav__item{
    list-style-type: none
}
html body .nav__item--dropdown{
    display: flex;
    align-items: center;
}
html body .nav__item--dropdown::after{
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    /* content: ""; */
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

/* Inputs */

html body label{
    width: 100%;
    font-size: 1.3rem;
    text-transform: uppercase;
    margin-left: 1.5rem;
}

html body input:not([type="radio"]):not([type="checkbox"]):not([type="search"]){
    width: 100%;
    border-radius: 4rem;
    border: 0;
    outline: 0;
    padding: 1rem 3rem;
    transition: var(--transition);
}

html body textarea{
    width: 100%;
    border-radius: 1rem;
    border: 0;
    outline: 0;
    padding: 3rem;
    min-height: 17rem;
    transition: var(--transition);
}

html body input:not([type="radio"]):focus, html body textarea:focus{
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
}

html body input[type="radio"]{
    width: 2rem;
    height: 2rem;
}

html body .datepicker input{
    cursor: pointer;
    max-width: 14rem;
}

html body .optional__label{
    position: absolute;
    right: 3rem;
    top: 3.6rem;
    font-size: 1.2rem;
}

html body .select{
    display: flex;
    align-items: center;
}

html body select{
    width: 100%;
    border-radius: 4rem;
    border: 0;
    outline: 0;
    padding: 1rem 3rem;
    transition: var(--transition);
    border: 1px solid var(--grey) !important;
}

html body .dropdown--select{
    width: 100%;
}

html body .select__label{
    text-transform: uppercase;
}

html body .select .dropdown-menu{
    width: 100%;
}

html body .dropdown--select .dropdown-toggle{
    width: 100%;
    border-radius: 4rem;
    padding: 1rem 3rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--textLight);
}

html body .input--error label{
    color: var(--danger);
}

html body .input--error input{
    border: 1px solid var(--danger) !important;
}

html body .input--error .error__message{
    font-size: 1.2rem;
    color: var(--danger);
    margin-top: 0.5rem;
    margin-left: 1.5rem;
}

/* Input file */
input[type="file"]{
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    max-width: 100%;
}

/* Switch */
.pure-material-switch {
    z-index: 0;
    position: relative;
    display: inline-block;
    color: rgba(var(--grey, 0, 0, 0), 0.87);
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    width: 156px;
}

/* Input */
.pure-material-switch > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -10px;
    top: -10px;
    display: block;
    margin: 0;
    border-radius: 40px;
    width: 80px;
    height: 60px;
    background-color: rgba(var(--grey, 0, 0, 0), 0.38);
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
}

/* Span */
.pure-material-switch > span {
    display: inline-block;
    width: 156px;
    cursor: pointer;
    position: relative;
}

/* Track */
.pure-material-switch > span::before {
    content: "";
    display: inline-block;
    border-radius: 40px;
    width: 156px;
    height: 40px;
    background-color: var(--grey);
    vertical-align: top;
    transition: background-color 0.2s, opacity 0.2s;
}

/* Thumb */
.pure-material-switch > span::after {
    content: "";
    position: absolute;
    top: 6px;
    right: 105px;
    border-radius: 40px;
    width: 40px;
    height: 28px;
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    /* box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); */
    transition: background-color 0.2s, transform 0.2s;
}

/* Checked */
.pure-material-switch > input:checked {
    right: -10px;
    left: inherit;
    background-color: var(--primary);
}

.pure-material-switch > input:checked + span::before {
    background-color: var(--primary);
}

.pure-material-switch > input:checked + span::after {
    background-color: white;
    transform: translateX(96px);
}

/* Hover, Focus */
.pure-material-switch:hover > input {
    opacity: 0.04;
}

.pure-material-switch > input:focus {
    opacity: 0.12;
}

.pure-material-switch:hover > input:focus {
    opacity: 0.16;
}

/* Active */
.pure-material-switch > input:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
}

.pure-material-switch > input:active + span::before {
    background-color: var(--primary);
}

.pure-material-switch > input:checked:active + span::before {
    background-color: var(--primary);
}

/* Disabled */
.pure-material-switch > input:disabled {
    opacity: 0;
}

.pure-material-switch > input:disabled + span {
    color: rgb(var(--grey, 0, 0, 0));
    opacity: 0.38;
    cursor: default;
}

.pure-material-switch > input:disabled + span::before {
    background-color: rgba(var(--grey, 0, 0, 0), 0.38);
}

.pure-material-switch > input:checked:disabled + span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}


/* Tables */
html body .table-striped tbody tr:nth-of-type(odd){
    background-color: #f6f9fd;
}

html body .table td, html body .table th{
    border: 0;
}

/* Badge */

html body .badge{
    font-weight: 400;
    font-size: 1.4rem;
    width: 100%;
    padding: 0.5rem 1.5rem;
}

html body .badge--secondary{
    background-color: var(--grey);
}